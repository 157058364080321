<template>

  <div>

    <b-button
      v-if="hits.length"
      class="bg-gradient-primary mr-1"
      variant="primary"
      @click="playAll"
    >
      <feather-icon
        size="13"
        icon="PlayIcon"
        fill="#FFF"
        cursor="pointer"
      />
      Ouvir
    </b-button>

    <section
      id="knowledge-base-content"
      ref="musicList"
      class="divHits pt-2 pb-5"
    >

    <div 
        v-if="!hits.length && filtrar == 0 && !carregamentoApi"
      >

          <b-col
            cols="12"
            style="padding: 0px;"
          >
            <b-card
              :no-body="true"
              class="text-left p-3"
              style="overflow: hidden;"
            >
              
              <b-card-body>

                <div style="position: absolute; top: 0; right: 0;z-index:0;">
                  <img src="@/assets/images/bg/bg.png" alt="Ícone Favoritos" />
                </div>

                <div style="position: relative;z-index:2;">
                  <b-card-title class="text-white">
                    <h1 class="content-header-title">Bem-vindo aos <b>seus Hits</b></h1>
                  </b-card-title>
                  <b-card-text class="my-2">
                    <h6>
                      <b>Aqui você encontrará todos os 
                       seus hits.</b>
                    </h6>
                  </b-card-text>
                </div>

              </b-card-body>

            </b-card>
          </b-col>

      </div>

      <b-card
        v-for="hit in hits"
        :key="hit.id"
        :ref="checkActiveMusic(hit.id) ? 'activeMusic' : ''"
        :class="checkActiveMusic(hit.id) ? 'active-color' : ''"
        class="card-transaction hitPlayCard"
        no-body
      >

        <b-card-body>
          <div
            class="transaction-item"
          >
            <b-media no-body>
              <b-media-aside>

                <div
                  v-if="checkActiveMusic(hit.id) && getPlayingStatus"
                  class="boxContainer"
                >
                  <div class="box box1"></div>
                  <div class="box box2"></div>
                  <div class="box box3"></div>
                  <div class="box box4"></div>
                  <div class="box box5"></div>
                </div>

                <b-icon
                  v-else
                  font-size="30"
                  icon="play-circle"
                  cursor="pointer"
                  class="btPlay"
                  @click="selectMusic(hit);updateMusicListScroll($event);"
                />

              </b-media-aside>
              <b-media-body>
                <h6 class="transaction-title">
                  <span :class="checkActiveMusic(hit.id) ? 'active-text-color' : ''">{{ hit.nomeComposicao }}</span>
                </h6>
                <div class="audio-player" />
              </b-media-body>
            </b-media>

            <div class="d-flex align-items-center font-weight-bolder">
              <div :id="'plus-icon-tooltip-' + hit.id" class="mr-2">
                <b-tooltip :target="'plus-icon-tooltip-' + hit.id" placement="top"
                    container="body" >
                  Adicionar na Playlist
                </b-tooltip>
                <feather-icon
                  size="20"
                  icon="PlusIcon"
                  :class="{favorito: hit.favorito}"
                  style="cursor: pointer;"
                  @click="sidebarPlaylist(hit.id)"
                  />
              </div>

              <div :id="'search-icon-tooltip-' + hit.id" class="mr-2">
                <b-tooltip :target="'search-icon-tooltip-' + hit.id" placement="top"
                    container="body" >
                  Detalhes
                </b-tooltip>
                <feather-icon
                  size="20"
                  icon="SearchIcon"
                  :class="{favorito: hit.favorito}"
                  style="cursor: pointer"
                  @click="$router.push({ name: 'meus-hits-comprador-info', params: { id: hit.id } })"
                  />
              </div>
            </div>

          </div>
        </b-card-body>

      </b-card>
      <hit-comprador-playlist-musica-add
        :adicionarMusicaPlaylistSideBarAtivo.sync="adicionarMusicaPlaylistSideBarAtivo"
        :hit-id="hitIdPlaylistSelecionado || 0"
      />

      <infinite-loading
        ref="infiniteLoading"
        :infinite-scroll-disabled="carregamentoApi"
        @infinite="hitsLista"
        :identifier="filtrar"
        no-results="noResults"
      >

        <span slot="spinner">
          <img
            src="@/assets/images/logo/logo-loading.gif"
            width="40px"
          >
          <p>Carregando mais hits...</p>
        </span>
        <span slot="no-more"></span>
        <span slot="loading">Carregando mais hits...</span>

        <div slot="no-results">

          <div
            v-if="filtrar > 0"
          >
            <b-badge
              variant="light-danger"
            >
              Sem resultados
            </b-badge>
          </div>

        </div>

      </infinite-loading>

    </section>

  </div>
</template>

<script>

import Vue from 'vue'

import {
  BAlert, BRow, BCol, BModal, BCard, BCardTitle, BCardHeader, BCardBody, BForm, BFormInvalidFeedback, BFormGroup, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, IconsPlugin, BBadge, BButton, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BSpinner, BFormCheckbox,
  BSidebar, BTooltip 
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import vSelect from 'vue-select'

import useJwt from '@/auth/jwt/useJwt'

import VueAudio from 'vue-audio'

import AudioPlayer from '@liripeng/vue-audio-player'

// CARREGANDO DE PÁGINA PARA API
import axios from 'axios'

import aws4 from 'aws4'

import globalMixins from '@/mixins/globalMixins'
import musicsMixins from '@/mixins/musicsMixins'

import VueSlider from 'vue-slider-component'

import { Money } from 'v-money'

import Ripple from 'vue-ripple-directive'

import VueCurrencyFilter from 'vue-currency-filter'
import InfiniteLoading from 'vue-infinite-loading'
import HitPlayer from './HitPlayer.vue'
import HitPlay from './HitPlay'
import HitCompradorPlaylistMusicaAdd from '../usuarios/comprador/comprador-editar/HitCompradorPlaylistMusicaAdd.vue'

Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
})

export default {
  name: 'AllMusicsPlayList',
  components: {
    BSidebar,
    BTooltip,
    BAlert,
    BRow,
    BCol,
    BModal,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    IconsPlugin,
    BFormInput,
    BBadge,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BFormCheckbox,
    AudioPlayer,
    aws4,
    HitPlayer,
    HitCompradorPlaylistMusicaAdd,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
    VueSlider,
    Money,
    InfiniteLoading,
    VueCurrencyFilter,
  },
  directives: {
    Ripple,
  },
  mixins: [globalMixins, musicsMixins],
  data() {
    return {

      hits: [],
      carregamentoApi: false,
      audioList: ['http://www.ytmp3.cn/down/76865.mp3'],
      tocando: false,
      selected_options: [],
      activePlayList: 'all',

      filtrar: 0,

      page: 0,
      size: 8,
      sort: 'ASC',

      // FILTRO

      hitValorLiberacao: [0, 100000],
      hitValorExclusividade: [0, 100000],

      filtroLiberacaoMin: 0,
      filtroLiberacaoMax: 100000,
      filtroExclusividadeMin: 0,
      filtroExclusividadeMax: 100000,


      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },

      // FILTRO

      respostaApi: false,

      hitIdPlaylistSelecionado: null,
      adicionarMusicaPlaylistSideBarAtivo: false,
    }
  },
  watch: {
    filtroReproduzido(newValue, oldValue) {
      // Verifica se o novo valor é diferente do antigo para evitar chamadas desnecessárias
      if (newValue !== oldValue) {
        // Limpa a lista de hits antes de recarregar
        this.hits = [];
        // Reinicia a página para o primeiro conjunto de dados
        this.page = 0;
        // Chama a função hitsLista para carregar os hits com o novo filtro
        this.hitsLista(this.$refs.infiniteLoading);
      }
    },
  },
  methods: {
    // SIDEBAR PLAYLIST
    sidebarPlaylist(hitId) {
      this.hitIdPlaylistSelecionado = hitId;
      this.adicionarMusicaPlaylistSideBarAtivo = true;
    }, 
    
    // ALTERAR VALOR LIBERAÇÃO
    alterarValorLiberacao(val) {
      const valorLiberacao = val

      this.filtroLiberacaoMin = valorLiberacao[0]
      this.filtroLiberacaoMax = valorLiberacao[1]
    },

    alterarValorLiberacaoInput() {
      const valorLiberacao = []

      valorLiberacao.push(Number(this.filtroLiberacaoMin))
      valorLiberacao.push(Number(this.filtroLiberacaoMax))

      this.hitValorLiberacao = valorLiberacao
    },

    // ALTERAR VALOR LIBERAÇÃO

    // ALTERAR VALOR LIBERAÇÃO

    alterarValorExclusividade(val) {
      const valorExclusividade = val

      this.filtroExclusividadeMin = valorExclusividade[0]
      this.filtroExclusividadeMax = valorExclusividade[1]
    },

    alterarValorExclusividadeInput() {
      const valorExclusividade = []

      valorExclusividade.push(Number(this.filtroExclusividadeMin))
      valorExclusividade.push(Number(this.filtroExclusividadeMax))

      this.hitValorExclusividade = valorExclusividade
    },

    // ALTERAR VALOR LIBERAÇÃO

    // FORMATAR MOEDA

    formatarMoeda(val) {
      return val.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },

    // STATUS

    updatePlayList(playList) {
      this.activePlayList = playList
      this.$store.commit('updatePlayList', playList)
    },
    checkActivePlaylist(playlist) {
      return playlist == this.activePlayList
    },


    async hitsLista(infiniteLoading) {

      this.carregamentoApi = true

      this.usuarioId = JSON.parse(localStorage.getItem('usuarioData'))?.sub
      useJwt.hitPertenceCompradorLista({
        usuarioId: this.usuarioId,
        page: this.page,
        size: this.size,
        sort: this.sort,
      })
        .then(response => {
          if (response.data.content.length) {
            this.page += 1
            this.hits.push(...response.data.content)
            infiniteLoading.loaded()
          } else {
            infiniteLoading.complete()
          }

        })
        .catch(error => {

          infiniteLoading.complete()

        })
        .finally(() => {
          this.carregamentoApi = false
        })

    },

    handleScroll() {

      // this.hitsLista();

    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
@import '@core/scss/mercado-hit.scss';

.mh-play {
  display: block;
  width: 40px;
  height: 40px;
}


@media only screen and (max-width: 767px) {
    .transaction-title {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
    }

    .tooltip {
      display: none !important;
    }
}

.transaction-title {
  text-transform: lowercase;
  margin-top: 7px !important;
}

.transaction-title::first-letter {
    text-transform: capitalize;
}

.playlist-dropdown .dropdown-menu {
  left: auto !important; 
  right: 0 !important;  
  transform: translateX(180px);  
}

</style>
